import React from "react";
import "./AboutServiceComponent.css";
import Image3 from "../assets/lpo.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ImageCard from "./ImageCard";
import LetsConnect from "./LetsConnect";
import { Hexagon, TiledHexagons } from "tiled-hexagons";
import Footer from "./Footer";
const textStyle = {
  fontFamily: "Source Sans Pro",
  fill: "black",
  fontWeight: "bold",
};

const LPOServiceComponent = (props) => {
  return (
    <div className="about-component">
      <img className="about-image" src={Image3}></img>

      <div className="home-banner">
        <div className="bannerContainer home-banner1">
          <h1 className="home-banner-heading heading2">LPO Services</h1>
          <span className="home-banner-sub-heading bodySmall">
            <span>
              <span>
                <span>
                Welcome to  Infojiva LPO Services, Your trusted partner for Legal Process Outsourcing services. We understand that the legal industry demands precision, timeliness, and cost-effectiveness. Our dedicated team of legal professionals is here to streamline your legal operations, reduce costs, and enhance your firm's productivity.
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      <div>
      <h1 className="home-banner-heading heading2">Our Services</h1>
      <div className="service-cards">
    
        <div className="service-cards1">
          <div className="home-container03">
          
            <ImageCard
              title="Document Review"
              message="Our meticulous document review services ensure that you have a clear understanding of your legal documents, helping you make informed decisions."
            ></ImageCard>
            <ImageCard
              title="Legal Research"
              message="Stay ahead of the curve with our comprehensive legal research services, providing you with the insights and information you need to succeed."
            ></ImageCard>
            <ImageCard
              title="Contract Management"
              message="Simplify contract management with our solutions that improve contract visibility, compliance, and risk management."
            ></ImageCard>
            <ImageCard
              title="Litigation Support"
              message="Benefit from our litigation support services that include e-discovery, case analysis, and trial preparation, helping you build strong cases."
            ></ImageCard>
          </div>
        </div>
      </div>
      </div>
      <div className="home-features">
        <div className="featuresContainer">
          <div className="home-features1">
            <div className="home-container02">
              <h2 className="home-features-heading heading2">
                Why Choose Us ?
              </h2>
              <span className="home-features-sub-heading bodyLarge"></span>
            </div>
            <div className="home-container03">
              <div>
              <div className="number-text">
                01
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Legal Expertise"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                Our team of experienced legal professionals specializes in various areas of law, ensuring that your tasks are handled by experts in the field.
                </div>
              </div>
              <div>
              <div className="number-text">
                02
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Cost Savings"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                Outsourcing legal processes to us can significantly reduce your operational costs, allowing you to allocate resources more strategically.
                </div>
              </div>
              <div>
              <div className="number-text">
                03
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Data Security"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                We prioritize the security and confidentiality of your legal data. Our advanced security measures guarantee the protection of sensitive information.
                </div>
              </div>
              <div>
              <div className="number-text">
                04
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Global Reach"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                With a global presence, we can provide around-the-clock support, ensuring that your legal processes are never delayed.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LetsConnect heading={"Start Optimizing Your Legal Processes Today"} message={"Ready to experience the benefits of outsourcing with Infojiva IT Services LLP. ? Contact us now to discuss your specific needs, and let us tailor an LPO solution that enhances efficiency, cost savings, and success for your law firm."}></LetsConnect>
      <Footer></Footer>
    </div>
  );
};
export default LPOServiceComponent;
