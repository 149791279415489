import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardHeader } from '@mui/material';

export default function ImgMediaCard(props) {
  return (
    <Card sx={{ maxWidth: 300}}>
      <CardHeader
       title={props.title}
      />
      <CardContent>
       
        <Typography variant="body2" color="text.primary">
       {props.message}
        </Typography>
      </CardContent>
    </Card>
  );
}