import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import BPOServices from './views/bpoServices'
import NotFound from './views/not-found'
import LPOServices from './views/lpoServices'
import IPServices from './views/ipServices'
import ITSolutions from './views/itSolutions'
import Contact from './views/contactus'
import Career from './views/career'

const App = () => {
  return (
    <BrowserRouter>
    <Routes>
        <Route element={<Home/>} exact path="/" />
        <Route element={<BPOServices/>} exact path="/bpoServices" />
        <Route element={<LPOServices/>} exact path="/lpoServices" />
        <Route element={<IPServices/>} exact path="/ipservices" />
        <Route element={<ITSolutions/>} exact path="/itsolutions" />
        <Route element={<Contact/>} exact path="/contact" />
        <Route element={<Career/>} exact path="/career" />
        <Route element={<NotFound/>} path="**" />
    </Routes>
    </BrowserRouter>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
