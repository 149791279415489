import React from 'react'



const ImageText = (props) => {
  return (
    <div className="image-text">
    <img src={props.img} />
    <div className='text-on-image'>
      <h1 className="home-hero-heading heading1">
        {props.heading}
      </h1>
      <span className="home-hero-sub-heading bodyLarge">
        <span>
          <span>{props.message}</span>
        </span>
      </span>
      {/* <div className="home-btn-group">
        <button className="buttonFilled">Get Started</button>
        <button className="buttonFilled">Learn More →</button>
      </div> */}
    </div>
  </div>
  )
}
export default ImageText;