import React from "react";
import "./AboutServiceComponent.css";
import Image3 from "../assets/bpo.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ImageCard from "./ImageCard";
import LetsConnect from "./LetsConnect";
import { Hexagon, TiledHexagons } from "tiled-hexagons";
import Footer from "./Footer";
const textStyle = {
  fontFamily: "Source Sans Pro",
  fill: "black",
  fontWeight: "bold",
};

const AboutServiceComponent = (props) => {
  return (
    <div className="about-component">
      <img className="about-image" src={Image3}></img>

      <div className="home-banner">
        <div className="bannerContainer home-banner1">
          <h1 className="home-banner-heading heading2">BPO Services</h1>
          <span className="home-banner-sub-heading bodySmall">
            <span>
              <span>
                <span>
                  At Infojiva IT Services LLP., we specialize in providing
                  top-notch Business Process Outsourcing (BPO) services tailored
                  to meet the unique needs of your business. With a commitment
                  to excellence and a team of highly skilled professionals, we
                  aim to streamline your operations, reduce costs, and help your
                  organization achieve new levels of success.
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      <div>
      <h1 className="home-banner-heading heading2">Our Services</h1>
      <div className="service-cards">
    
        <div className="service-cards1">
          <div className="home-container03">
          
            <ImageCard
              title="Customer Support"
              message="Our customer support solutions are designed to enhance customer satisfaction, improve response times, and drive brand loyalty. Whether you need inbound or outbound support, our dedicated team is here to assist."
            ></ImageCard>
            <ImageCard
              title="Data Entry"
              message="Accuracy and efficiency are the cornerstones of our data entry services. We handle data with precision, ensuring that your valuable information is processed and organized to meet your requirements."
            ></ImageCard>
            <ImageCard
              title="Back Office Support"
              message="From administrative tasks to data management, our back-office support services are geared to optimize your backend operations, allowing you to focus on core business functions."
            ></ImageCard>
            <ImageCard
              title="Lead Generation"
              message="We generate high-quality leads that convert into sales. Our strategic lead generation services help your business expand its reach and drive revenue growth"
            ></ImageCard>
          </div>
        </div>
      </div>
      </div>
      <div className="home-features">
        <div className="featuresContainer">
          <div className="home-features1">
            <div className="home-container02">
              <h2 className="home-features-heading heading2">
                Why Choose Us ?
              </h2>
              <span className="home-features-sub-heading bodyLarge"></span>
            </div>
            <div className="home-container03">
              <div>
              <div className="number-text">
                01
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Experience"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                  With decades of industry experience, we understand the ins and
                  outs of BPO services, enabling us to provide innovative
                  solutions that meet your unique needs.
                </div>
              </div>
              <div>
              <div className="number-text">
                02
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Dedicated Team"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                  Our team of experts is committed to delivering exceptional
                  results. We invest in ongoing training and technology to stay
                  at the forefront of the industry.
                </div>
              </div>
              <div>
              <div className="number-text">
                03
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Cost-Efficiency"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                  Outsourcing your business processes to us can significantly
                  reduce operational costs, allowing you to allocate resources
                  where they matter most.
                </div>
              </div>
              <div>
              <div className="number-text">
                04
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Data Security"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                  We prioritize the security and confidentiality of your data.
                  Our robust data protection measures ensure that your
                  information is safe in our hands.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LetsConnect heading={"Get Started Today"} message={"Ready to experience the benefits of outsourcing with Infojiva IT Services LLP.? Contact us today to discuss your specific needs, and let us tailor a BPO solution that drives efficiency, cost savings, and growth for your business."}></LetsConnect>
      <Footer></Footer>
    </div>
  );
};
export default AboutServiceComponent;
