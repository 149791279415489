import React, { useState } from 'react';
import "./LetsConnect.css";
import { TextField, Button, Select, MenuItem } from "@mui/material";

export default function LetsConnect(props) {
  const [selectValue, setSelectValue ] = useState('Select Services')
  const handleChange = (event) => {
    setSelectValue(event.target.value);
  };
  return (
    <div className="lets-connect">
      <div className="container">
        <div className="row">
          <div className="lets-connect-text">
            <div>
              <h2>{props.heading}</h2>
            </div>

            <p>
            {props.message}
            </p>
          </div>

          <div className="connect-form">
            <form autoComplete="off">
              <TextField
                label="Full Name"
                required
                variant="outlined"
                color="secondary"
                type="text"
                fullWidth
                sx={{ mb: 3 }}
              />
              <TextField
                label="Email"
                required
                variant="outlined"
                color="secondary"
                type="email"
                sx={{ mb: 3 }}
                fullWidth
              />
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Select Services"
    sx={{ mb: 3 }}
    value={selectValue}
    onChange={handleChange}
  >
    <MenuItem value={'Select Services'}>Select Services</MenuItem>
    <MenuItem value={'BPO Services'}>BPO Services</MenuItem>
    <MenuItem value={'LPO Services'}>LPO Services</MenuItem>
    <MenuItem value={'IP Services'}>IP Services</MenuItem>
    <MenuItem value={'IT Solutions'}>IT Solutions</MenuItem>
  </Select>
              <TextField
                label="Phone Number"
                required
                variant="outlined"
                color="secondary"
                type="number"
                sx={{ mb: 3 }}
                fullWidth
              />

              <Button variant="outlined" color="secondary" type="submit">
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
