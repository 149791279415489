import React from "react";
import "./AboutServiceComponent.css";
import Image3 from "../assets/ip.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ImageCard from "./ImageCard";
import LetsConnect from "./LetsConnect";
import { Hexagon, TiledHexagons } from "tiled-hexagons";
import Footer from "./Footer";
const textStyle = {
  fontFamily: "Source Sans Pro",
  fill: "black",
  fontWeight: "bold",
};

const IPServiceComponent = (props) => {
  return (
    <div className="about-component">
      <img className="about-image" src={Image3}></img>

      <div className="home-banner">
        <div className="bannerContainer home-banner1">
          <h1 className="home-banner-heading heading2">IP Services</h1>
          <span className="home-banner-sub-heading bodySmall">
            <span>
              <span>
                <span>
                Welcome to Infojiva IP Services LLP, your trusted partner for Intellectual Property services. We understand the importance of protecting and monetizing your intellectual assets. Our team of experienced IP professionals is here to safeguard your innovations, trademarks, and creative works while maximizing their value.
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      <div>
      <h1 className="home-banner-heading heading2">Our Services</h1>
      <div className="service-cards">
    
        <div className="service-cards1">
          <div className="home-container03">
          
            <ImageCard
              title="Patent Services"
              message="Our patent experts can help you secure, manage, and defend your valuable patents, ensuring your innovations are protected and leveraged to their fullest potential."
            ></ImageCard>
            <ImageCard
              title="Trademark Services"
              message="Protect your brand identity with our comprehensive trademark services, including trademark registration, monitoring, and enforcement."
            ></ImageCard>
            <ImageCard
              title="Copyright Services"
              message="Safeguard your creative works, literary compositions, and software with our copyright services, providing you with legal protection and peace of mind."
            ></ImageCard>
            <ImageCard
              title="IP Strategy"
              message=": Our strategic consultants can assist you in developing a tailored Intellectual Property strategy that aligns with your business goals, whether it's IP portfolio management or technology commercialization."
            ></ImageCard>
          </div>
        </div>
      </div>
      </div>
      <div className="home-features">
        <div className="featuresContainer">
          <div className="home-features1">
            <div className="home-container02">
              <h2 className="home-features-heading heading2">
                Why Choose Us ?
              </h2>
              <span className="home-features-sub-heading bodyLarge"></span>
            </div>
            <div className="home-container03">
              <div>
              <div className="number-text">
                01
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Expertise"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                Our team comprises seasoned IP professionals with a deep understanding of IP laws and regulations, ensuring the highest level of protection for your intellectual assets.
                </div>
              </div>
              <div>
              <div className="number-text">
                02
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Custom Solutions"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                We offer tailored solutions to meet your unique needs, whether you're a startup, a growing business, or an established corporation.
                </div>
              </div>
              <div>
              <div className="number-text">
                03
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Global Reach"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                With a global network of associates, we can help you navigate international IP protection, allowing you to expand your market reach.
                </div>
              </div>
              <div>
              <div className="number-text">
                04
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Confidentiality"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
               We prioritize the confidentiality and security of your intellectual property, providing you with a trusted partner in safeguarding your innovations.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LetsConnect heading={"Start Protecting and Monetizing Your IP Today"} message={"Ready to unlock the full potential of your intellectual property? Contact us now to discuss your specific needs, and let us tailor an IP strategy that safeguards your innovations and maximizes their value."}></LetsConnect>
      <Footer></Footer>
    </div>
  );
};
export default IPServiceComponent;
