import React from "react";

import { Helmet } from "react-helmet";

import FeatureCard from "../components/feature-card";
import Question1 from "../components/question1";
import "./home.css";

import Image1 from "../assets/image1.jpg";
import Image2 from "../assets/image2.jpg";
import Image3 from "../assets/image3.jpg";
import Image4 from "../assets/image4.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ImageText from "../components/ImageText";
import HeaderComponent from '../components/HeaderComponent'
import Footer from "../components/Footer";

const Home = (props) => {
  const navigate = useNavigate();
  return (
    <div className="home-container">
      <Helmet>
        <title>InforJiva</title>
        <meta property="og:title" content="InforJiva" />
      </Helmet>
  <HeaderComponent />
      <div className="home-hero">
        <div className="heroContainer home-hero1">
          <div className="home-container01">
            <Carousel
              style={{ height: "30px" }}
              autoPlay={true}
              infiniteLoop={true}
              interval={3000}
              showStatus={false}
              showThumbs={false}
              transitionTime={2000}
            >

              <ImageText img={Image3} heading={"Empowering Your Business with IT Solutions"} message={"Your IT Solutions Partner"}/>
              <ImageText img={Image4} heading={"Empowering Your Business with IT Solutions"} message={"Your IT Solutions Partner"}/>
            </Carousel>
          </div>
        </div>
      </div>
      <div className="home-features">
        <div className="featuresContainer">
          <div className="home-features1">
            <div className="home-container02">
              <span className="overline">
                <span>Services</span>
                <br></br>
              </span>
              <h2 className="home-features-heading heading2">
                Our Key Services
              </h2>
              <span className="home-features-sub-heading bodyLarge">
              </span>
            </div>
            <div className="home-container03">
              <FeatureCard
                heading="Examination Center"
                subHeading="We provide technological background for secure and reliable online exams"
              ></FeatureCard>
              <FeatureCard
                heading="BPO Services"
                subHeading="We specialize in providing top notch BPO services to meet the unique needs of your Business"
              ></FeatureCard>
              <FeatureCard
                heading="LPO Services"
                subHeading="We undertake the legal industry demands precision,timeliness and cost effectiveness"
              ></FeatureCard>
              <FeatureCard
                heading="Intellectual Property Services"
                subHeading="We Safeguard your innovations,trademarks and creative works while maximizing their values"
              ></FeatureCard>
            </div>
          </div>
        </div>
      </div>
      <div className="home-banner">
        <div className="bannerContainer home-banner1">
          <h1 className="home-banner-heading heading2">
            Transforming Businesses through Technology
          </h1>
          <span className="home-banner-sub-heading bodySmall">
            <span>
              <span>
                <span>
                  We are your trusted IT solutions partner. With a wealth of
                  experience and a passion for innvoation we specialize in
                  delivering tailored IT solutions that empower busoness to
                  thrive in the digital age. Our expert team crafts customized
                  stratergies and leverages cuting-edge technologies to address
                  your unique challenges. We prioritize security,reliability and
                  cost effectiveness ensuring your business remains competetive.
                  At INFOJIVA, our client satisfaction is our top priority, and
                  our commitment to building lasting partnerships shines through
                  in every project. Choose INFOJIVA for IT excellence that drive
                  your success.
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
              </span>
              <span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
              </span>
            </span>
          </span>
          <button className="buttonFilled">Learn More</button>
        </div>
      </div>
      <div className="home-faq">
        <div className="faqContainer">
          <div className="home-faq1">
            <div className="home-container29">
              <span className="overline">
                <span>FAQ</span>
                <br></br>
              </span>
              <h2 className="home-text85 heading2">Common questions</h2>
              <span className="home-text86 bodyLarge">
                <span>
                  Here are some of the most common questions that we get.
                </span>
                <br></br>
              </span>
            </div>
            <div className="home-container30">
              <Question1
                answer="We offer a BPO services, LPO services, Exam Center and Intellectual Property services"
                question="What services do you offer?"
              ></Question1>
              <Question1
                answer="Our team consists of highly experienced professionals with years of expertise in the IT industry."
                question="How experienced is your team?"
              ></Question1>
              <Question1
                answer="You can easily request a consultation by filling out our online form or contacting us directly via phone or email."
                question="How can I request a consultation?"
              ></Question1>
            </div>
          </div>
        </div>
      </div>

     <Footer></Footer>
    </div>
  );
};

export default Home;
