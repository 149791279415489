import React from "react";
import "./ContactComponent.css";
import Image3 from "../assets/banneraboutus.svg";
import { Hexagon, TiledHexagons } from "tiled-hexagons";
import Footer from "./Footer";
import ImageText from "./ImageText";
import PlaceIcon from '@material-ui/icons/Place';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import { TextField, TextareaAutosize, Button } from "@mui/material";
const textStyle = {
  fontFamily: "Source Sans Pro",
  fill: "black",
  fontWeight: "bold",
};

const ContactComponent = (props) => {
  return (
    <div className="contact-component">
      <img src={Image3} />
      <div className="contact-us-container">
        <div className="contact">
          <div className="heading">
             <h2> Infojiva IT Services LLP </h2>
          </div>
          <div className="address-container">
            <ul className="address-list">
              <li className="address-list-item">
                <span><PlaceIcon /></span>
                <span className="list-item-text">CTS No.9336, House No.23, M M Extn, Mahantesh
          Nagar, Belagavi, Karnataka 590016</span></li>
          <li className="address-list-item">
            <span><CallIcon /></span>
            <span className="list-item-text"> 08312009092</span>
          </li>
          <li className="address-list-item">
            <span><EmailIcon /></span>
            <span className="list-item-text">contact@infrojiva.com</span>
          </li>
            </ul>
          </div>
          
        </div>
        <div className="connect-form">
          <form autoComplete="off">
            <TextField
              label="Full Name"
              required
              variant="outlined"
              color="secondary"
              type="text"
              fullWidth
              sx={{ mb: 3 }}
            />
            <TextField
              label="Email"
              required
              variant="outlined"
              color="secondary"
              type="email"
              sx={{ mb: 3 }}
              fullWidth
            />
     
            <TextField
              label="Phone Number"
              required
              variant="outlined"
              color="secondary"
              type="number"
              sx={{ mb: 3 }}
              fullWidth
            />
            
            <TextField
              label="Message"
              required
              t
              variant="outlined"
              color="secondary"
              multiline
              fullWidth
              sx={{ mb: 3 }}
  rows={3}
  maxRows={4}
            />

            <Button variant="outlined" color="secondary" type="submit">
              Submit
            </Button>
          </form>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};
export default ContactComponent;
