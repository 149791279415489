import React from 'react'
import BrandLogo from "../assets/logo.jpeg";
import { useLocation, useNavigate } from 'react-router-dom';
import './HeaderComponent.css';
import { slide as Menu } from 'react-burger-menu'

const HeaderComponent = (props) => {
    const navigate = useNavigate();
  return (
    <div className="home-header">
    <header
      data-thq="thq-navbar"
      className="navbarContainer home-navbar-interactive"
    >
      <div className="brand-logo-container">
        <img width="150" height="150" src={BrandLogo} alt="niagara cloud" />
      </div>
      {/* <span className="logo">INFOJIVA</span> */}
      <div data-thq="thq-navbar-nav" className="home-desktop-menu">
        <nav className="home-links"></nav>
        <div className="home-buttons">
          <button
            className="home-login buttonFlat"
            onClick={() => navigate("/")}
          >
            Home
          </button>
        <div className='home-login dropdown'> 
        <button
            className="buttonFlat dropbtn"
          >
            Services
          </button>
          <div className="dropdown-content">
    <a className="buttonFlat" href="/bpoServices">BPO Services</a>
    <a className="buttonFlat" href="/lpoServices">LPO Services</a>
    <a className="buttonFlat" href="/ipservices">IP Services</a>
    <a className="buttonFlat" href="/itsolutions">IT Solutions</a>
  </div>
        </div>
         
          <button
            className="home-login buttonFlat"
            onClick={() => navigate("/career")}
          >
            Career
          </button>
          <button
            className="home-login buttonFlat"
            onClick={() => navigate("/contact")}
          >
            Contact
          </button>
          {/* <button
            className="home-login buttonFlat"
            onClick={() => navigate("/about")}
          >
            About Us
          </button> */}
        </div>
      </div>
      <div className='hamburger-menu'>
      <Menu>
        <a id="home" className="menu-item" href="/">Home</a>
        <div className="onclick-menu">Services
                    <ul className="onclick-menu-content">
                    <li><a  href="/bpoServices">BPO Services</a></li>
                    <li><a  href="/lpoServices">LPO Services</a></li>
                    <li><a  href="/ipservices">IP Services</a></li>
                    <li><a  href="/itsolutions">IT Solutions</a></li>
                    </ul>
                    </div>
        <a id="about" className="menu-item" href="/career">Career</a>
        <a id="contact" className="menu-item" href="/contact">Contact</a>
      </Menu>
      </div>
    
    </header>
  </div>
  )
}
export default HeaderComponent;