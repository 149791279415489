import React from 'react'

import { Helmet } from 'react-helmet'

import FeatureCard from '../components/feature-card'
import Question1 from '../components/question1'
import './home.css'
import BrandLogo from '../assets/logo.jpeg';

import { useLocation, useNavigate } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import HeaderComponent from '../components/HeaderComponent'
import IPServiceCoomponent from '../components/IPServiceCoomponent'
import ImageText from "../components/ImageText";


export default function IPServices(props) {
  const navigate = useNavigate();
  return (
    <div className="home-container">
      <Helmet>
        <title>InforJiva</title>
        <meta property="og:title" content="InforJiva" />
      </Helmet>
      <HeaderComponent />
      <div className="home-hero">
        <div className="heroContainer home-hero1">
          <div className="home-container01">
              <IPServiceCoomponent></IPServiceCoomponent>
          </div>
        </div>
      </div>
</div>
  )
  }
    
    