import React from "react";
import "./CareerComponent.css";
import Image3 from "../assets/career.jpg";
import { Hexagon, TiledHexagons } from "tiled-hexagons";
import Footer from "./Footer";
import ImageText from "./ImageText";
import PlaceIcon from "@material-ui/icons/Place";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import { TextField, TextareaAutosize, Button } from "@mui/material";
const textStyle = {
  fontFamily: "Source Sans Pro",
  fill: "black",
  fontWeight: "bold",
};

const CareerComponent = (props) => {
  return (
    <div className="career-component">
      <div className="container-1">
        <div className="left-container">
          <img src={Image3} />
          <div className="learn-more">
            <h2>Learn More About Open Opportunities</h2>
            <div className="connect-form">
              <form autoComplete="off">
                <TextField
                  label="Full Name"
                  required
                  variant="outlined"
                  color="secondary"
                  type="text"
                  fullWidth
                  sx={{ mb: 3 }}
                />
                <TextField
                  label="Email"
                  required
                  variant="outlined"
                  color="secondary"
                  type="email"
                  sx={{ mb: 3 }}
                  fullWidth
                />

                <TextField
                  label="Phone Number"
                  required
                  variant="outlined"
                  color="secondary"
                  type="number"
                  sx={{ mb: 3 }}
                  fullWidth
                />

                <TextField
                  label="Message"
                  required
                  t
                  variant="outlined"
                  color="secondary"
                  multiline
                  fullWidth
                  sx={{ mb: 3 }}
                  rows={3}
                  maxRows={4}
                />

                <Button variant="outlined" color="secondary" type="submit">
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </div>

        <div className="container-2">
          <div className="container-3">
            <h2>Life at InfoJiva</h2>
            <div>
              <p>
                A great working environment is a heady mixture that induces
                people to work with all their passion. It keeps them performing
                even in your absence, inspires to achieve bigger without the
                need to push, contributes to your brand taking little in return.
                Developing a good work environment involves working on
                streamlining the organizational structure, internal
                communication, core process, and work space as well as
                recognizing the talent.
              </p>
            </div>
          </div>
          <div className="container-3">
            <h2>Hierarchy Redefined</h2>
            <div>
              <p>
                Hierarchies define how company processes work and how people
                connect with each other. At InfoJiva, if you are good at
                something, you become the consultant of that task, leading and
                guiding the team. In other words, it is a slight twist to the
                perfect model practiced in general. A performer is always
                recognized.
              </p>
            </div>
          </div>
          <div className="container-3">
            <h2>Eliminating Bottlenecks and Bridging Gaps</h2>
            <div>
              <p>
                Hierarchical levels need not create communication bottlenecks,
                draining the energy and enthusiasm out of people at work.
                Learning from the experts, getting tips on eliminating bugs, or
                sharing your own ideas on solving problems should keep you tuned
                for the future. We take care to bridge the gap and break away
                communication barriers with an open mindset, and let the ideas
                flow freely.
              </p>
            </div>
          </div>
          <div className="container-3">
            <h2>Innovation at Work</h2>
            <div>
              <p>
                Innovation keeps one ahead of the crowd and drives individuals
                to think outside the box and find feasible solutions for
                impossibilities. We are always on a mission to find solutions
                that are a perfect blend of what really works for clients, what
                technology can do, and what trends are in the market.
              </p>
            </div>
          </div>
          <div className="container-3">
            <h2>Passion to Excel</h2>
            <div>
              <p>
                Isn't it true that without the passion to overcome
                impossibilities, the world would have been a dull place? We love
                experimenting with code to devise newer things and make
                impressions in our products and solutions. Our passion holds us
                from reveling in past achievements and leads us to work on
                achieving new milestones and creating waves in the industry.
              </p>
            </div>
          </div>
          <div className="container-3">
            <h2>Striking Work-Life Balance</h2>
            <div>
              <p>
                Looking at the other side of work and talent, employees are also
                social beings walking on a tightrope of professional and
                personal commitments. People need friendly work environments to
                settle and unleash their true potential. We believe in better
                management-employee interaction, conflict-free, positive work
                environment, gender equality, room for personalization, and
                flexible work timings are some of the cues to create great
                workspaces.
              </p>
            </div>
          </div>
          <div className="container-3">
            <h2>Recognizing Talents</h2>
            <div>
              <p>
                People spend more years working than taking care of personal
                lives, and there's no reason why they should not explore their
                talents and make a difference in their lives. Allowing
                individuals to identify their true potential and create a niche
                in related fields, and recognizing their achievements is
                essential. We believe a great work environment, along with the
                best compensation in the market for a performer, should attract
                the right talent.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};
export default CareerComponent;
