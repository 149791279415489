import React from "react";
import "./AboutServiceComponent.css";
import Image3 from "../assets/itsolutions.jpeg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ImageCard from "./ImageCard";
import LetsConnect from "./LetsConnect";
import { Hexagon, TiledHexagons } from "tiled-hexagons";
import Footer from "./Footer";
const textStyle = {
  fontFamily: "Source Sans Pro",
  fill: "black",
  fontWeight: "bold",
};

const ITSolutionComponent = (props) => {
  return (
    <div className="about-component">
      <img className="about-image" src={Image3}></img>

      <div className="home-banner">
        <div className="bannerContainer home-banner1">
          <h1 className="home-banner-heading heading2">IT Solutions</h1>
          <span className="home-banner-sub-heading bodySmall">
            <span>
              <span>
                <span>
                At Infojiva IT Services LLP., we're your trusted partner in navigating the complex world of Information Technology. With a commitment to excellence and a track record of success, we're here to help you unlock the full potential of technology for your business.
                </span>
              </span>
            </span>
          </span>
        </div>
      </div>
      <div>
      <h1 className="home-banner-heading heading2">Our Services</h1>
      <div className="service-cards">
    
        <div className="service-cards1">
          <div className="home-container03">
          
            <ImageCard
              title="Services"
              message="Explore our comprehensive range of IT services, tailored to meet your unique business needs. Whether it's cloud solutions, cybersecurity, or data analytics, we have the expertise to deliver results."
            ></ImageCard>
          </div>
        </div>
      </div>
      </div>
      <div className="home-features">
        <div className="featuresContainer">
          <div className="home-features1">
            <div className="home-container02">
              <h2 className="home-features-heading heading2">
                Why Choose Us ?
              </h2>
              <span className="home-features-sub-heading bodyLarge"></span>
            </div>
            <div className="home-container03">
              <div>
              <div className="number-text">
                01
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Expertise"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                Our team of IT professionals brings years of experience and industry certifications to every project.
                </div>
              </div>
              <div>
              <div className="number-text">
                02
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Innovation"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                We stay at the forefront of technology trends to provide cutting-edge solutions.
                </div>
              </div>
              <div>
              <div className="number-text">
                03
                </div>
                <div className="hexagon">
                  <Hexagon
                    sideLength={80}
                    text="Client-Centric"
                    textStyle={textStyle}
                  />
                </div>
                <div className="choose-us-text">
                Your success is our priority, and we work closely with you to understand your IT challenges and deliver exceptional solutions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LetsConnect heading={"Ready to Transform Your IT?"} message={"Get in touch with us today to discuss how our IT solutions can elevate your business. Reach out through our contact form or give us a call at 08312009092."}></LetsConnect>
      <Footer></Footer>
    </div>
  );
};
export default ITSolutionComponent;
